const Theme: any = {};

Theme.colors = {
  primary: '#d4f186',
  secondary: '#5cb85c',
  info: '#5bc0de',
  warning: '#f0ad4e',
  danger: '#d9534f',
  inverse: '#ffffff',
  faded: '#000000',
  gray: '#9d9d9d',
};

Theme.typography = {
  sansSerifFontFamily: '"IBM Plex Mono", monospace',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1.5',
  color: Theme.colors.inverse,
};

export { Theme };

export type ThemeType = typeof Theme;
